/** @format */

import React from "react";

const Buy = () => {
  return (
    <>
      <div className='buy_sec py-20'>
        <div className='overlay'></div>
        <div className='container'>
          <div className='flex  justify-center gap-6 items-center flex-wrap my-2'>
            <a
              href='https://www.pinksale.finance/solana/launchpad/5bU6EoLbSwH6M8XbHwCNhJiU59ya3z9qtj5ikcZhKHye'
              target='_blank'
              rel='noopener noreferrer'
              className=' w-1/6'>
              <img
                src='assets/images/pinksale-1.png'
                className='w-full mt-2 object-contain '
                alt=''
              />
            </a>
            <a
              href='https://www.dexview.com/solana/7QSCmz8oCApibDEmQRiHXabK15GmnJkoKGhGnjeVVWNz'
              target='_blank'
              rel='noopener noreferrer'
              className=' w-1/6'>
              <img
                src='assets/images/dexview.svg'
                className='w-full mt-2 object-contain '
                alt=''
              />
            </a>
            <a
              href='https://raydium.io/swap/?inputCurrency=7QSCmz8oCApibDEmQRiHXabK15GmnJkoKGhGnjeVVWNz&outputCurrency=sol&fixed=in'
              target='_blank'
              rel='noopener noreferrer'
              className=' w-1/6'>
              <img
                src='assets/images/logo-with-text.svg'
                className='w-full mt-2 object-contain '
                alt=''
              />
            </a>
            <a
              href='https://blocksafu.com/audit-solana/7QSCmz8oCApibDEmQRiHXabK15GmnJkoKGhGnjeVVWNz'
              className=' w-1/6'
              target='_blank'
              rel='noopener noreferrer'>
              <img
                src='assets/images/blocksafu.svg'
                className='w-full mt-2 object-contain '
                alt=''
              />
            </a>
          </div>
          <div className='row px-4 mt-5 '>
            <div className='col-md-4  overlay2 px-3 '></div>
            <div className='col-md-8 text-white '>
              <h4 className='uppercase font-[700] lg:text-6xl text-4xl'>
                Is FUFU Coin
              </h4>
              <h3 className='capitalize  font-[700] lg:text-4xl text-4xl'>
                a Safe Investment?
              </h3>
              <p className='font-sans font-medium lg:text-lg mt-3'>
                Let’s be honest! Probably Not. (I added a humorous way to answer
                that the risk is involved) But listen, at least you will look
                fantastic in that FUFU Coin T-shirt while you wait for your
                investment to make you a millionaire.{" "}
              </p>
              <p className='font-sans font-medium lg:text-lg '>
                (Trying to indicate that even if investment fails, you will have
                a cool shirt).
              </p>
              <div className='mt-5'>
                <a
                  href='https://t.me/fufucoin_official'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <button className='uppercase px-4 py-2 mx-1'>
                    {" "}
                    <img
                      src='assets/images/telegram.png'
                      className='lg:w-7 w-5  inline-block '
                      alt=''
                    />{" "}
                    telegram
                  </button>
                </a>
                <a
                  href='https://twitter.com/Fufucoin_'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <button className='uppercase px-4 py-2 mx-1'>
                    {" "}
                    <img
                      src='assets/images/Twitter.png'
                      className='lg:w-7 w-5 inline-block'
                      alt=''
                    />
                  </button>
                </a>
                <a
                href='https://game.fufusol.xyz/'
                target='_blank'
                rel='noopener noreferrer'>
                <button className='fufu-btn uppercase px-4 py-2 mx-1 m-auto'>
                  Game Play
                </button>
              </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Buy;
