/** @format */

import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
const Hero = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    const audio = audioRef.current;

    if (isPlaying) {
      audio.play().catch(error => {
        // Handle play error
        console.log("Failed to play audio:", error);
      });
    } else {
      audio.pause();
    }
  }, [isPlaying]); // Run this effect whenever `isPlaying` changes

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
    handleClose();
  };

  useEffect(() => {
    handleShow();
  }, []);
  return (
    <>
      <>
        <a
          href='https://game.fufusol.xyz/'
          target='_blank'
          rel='noopener noreferrer'
          className=' w-3/2 h-full hero_sec  no-underline'>
          <div className='overlay rounded-lg py-16'>
            <div className='flex justify-center'>
              <img src='/fufu.png' className='w-1/6 shakee' alt='' />
            </div>
            <h2 className='text-center text-white lg:text-[138px] text-4xl lg:font-[700] font-[800]  leading-none'>
              WELCOME TO <br />
              FUFU
            </h2>
            <div className='text-center'>
              <button
                className='uppercase px-4 py-2 mx-1 m-auto '
                onClick={togglePlay}>
                {isPlaying ? "Pause" : "Play"}
              </button>
              <a
                href='https://raydium.io/swap/?inputCurrency=7QSCmz8oCApibDEmQRiHXabK15GmnJkoKGhGnjeVVWNz&outputCurrency=sol&fixed=in'
                target='_blank'
                rel='noopener noreferrer'>
                <button className='uppercase px-4 py-2 mx-1 m-auto '>
                  Trade on Raydium
                </button>
              </a>
              <a
                href='https://game.fufusol.xyz/'
                target='_blank'
                rel='noopener noreferrer'>
                <button className='fufu-btn uppercase px-4 py-2 mx-1 m-auto'>
                  Game Play
                </button>
              </a>
              <audio
                ref={audioRef}
                src='/music.mp3'
                autoPlay
                loop
                playsInline
              />
            </div>
          </div>
        </a>
      </>
      <Modal show={show}>
        <Modal.Header className='border-0 '>
          <img src='/fufu.png' className='img-fluid w-1/4 m-auto' alt='' />
        </Modal.Header>
        <Modal.Body>
          {" "}
          <h4 className=' font-[700] lg:text-3xl text-lg text-white text-center'>
            Join the FUFU Coin Revolution Today!
          </h4>
          <h5 className=' font-[600] lg:text-xl text-4xl text-yellow-400 text-center'>
            Don’t miss the FUFU Coin Train!! Jump on board and join the
            revolution!.{" "}
          </h5>
          <div className='text-center mt-4'>
            <div className='col-12'>
              <button
                onClick={togglePlay}
                className='uppercase px-4 py-2 mx-1 m-auto '>
                Got it
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Hero;
