/** @format */

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className='footer '>
        <div className='py-8'>
          <div className='flex justify-center flex-col items-center'>
            <img src='/fufu.png' className='w-40 imgg' alt='' />
            <h4 className=' font-[700] lg:text-6xl text-4xl text-white'>
              Join the FUFU Coin Revolution Today!
            </h4>
            <h4 className=' font-[700] lg:text-3xl text-4xl text-white'>
              Don’t miss the FUFU Coin Train!! Jump on board and join the
              revolution!.{" "}
            </h4>
          </div>
        </div>
        <div className='flex justify-center space-x-4'>
          <a
            target='_blank'
            href='https://twitter.com/Fufucoin_'
            rel='noopener noreferrer'
            className='lg:p-[7px] p-[8px] rounded-full bg-black'>
            <img
              src='assets/images/Twitter.png'
              className='lg:w-7 w-5 '
              alt=''
            />
          </a>
          <a
            target='_blank'
            href='https://t.me/fufucoin_official'
            rel='noopener noreferrer'
            className='lg:p-[7px] p-[8px] rounded-full bg-black'>
            <img
              src='assets/images/telegram.png'
              className='lg:w-7 w-5  '
              alt=''
            />
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://www.pinksale.finance/solana/launchpad/5bU6EoLbSwH6M8XbHwCNhJiU59ya3z9qtj5ikcZhKHye'
            className='lg:p-[7px] p-[8px] rounded-full bg-black'>
            <img
              src='assets/images/pinksale.png'
              className='lg:w-7 w-5 '
              alt=''
            />
          </a>
          <a
            target='_blank'
            href='https://www.dexview.com/solana/7QSCmz8oCApibDEmQRiHXabK15GmnJkoKGhGnjeVVWNz'
            rel='noopener noreferrer'
            className='lg:p-[7px] p-[8px] rounded-full bg-black'>
            <img
              src='assets/images/Dexview.png'
              className='lg:w-7 w-5  invert'
              alt=''
            />
          </a>
          {/* <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://blocksafu.com/audit-solana/7QSCmz8oCApibDEmQRiHXabK15GmnJkoKGhGnjeVVWNz'
            className='lg:p-[7px] p-[8px] rounded-full bg-black'>
            <img src='assets/images/56.png' className='lg:w-7 w-5 ' alt='' />
          </a> */}
        </div>
      </footer>
    </>
  );
};

export default Footer;
