/** @format */

import React from "react";

const Tokenomics = () => {
  return (
    <>
      <div className='tokenomics py-12 px-3'>
        <div className='overlay'></div>
        <div className='container-fluid'>
          <div className='text-white'>
            <h4 className='uppercase text-center lg:font-[700] font-[800]  lg:text-6xl text-4xl'>
            FUFU
            </h4>
            <h3 className='capitalize text-center lg:font-[700] font-[800] lg:text-9xl text-4xl rotate-[4deg]'>
              TOKENOMICS
            </h3>
          </div>
          <div className='flex lg:justify-start text-white justify-center lg:px-14 mt-20'>
            <div>
              <h3 className='capitalize text-center lg:font-[700] font-[800] lg:text-7xl text-4xl rotate-[-10deg]'>
                Symbol
              </h3>
              <h4 className='uppercase text-center font-[700] lg:text-5xl  mt-4'>
                FUFU
              </h4>
            </div>
            <div>
              <h3 className='capitalize text-center lg:font-[700] font-[800] lg:text-7xl text-4xl rotate-[5deg]'>
              Current 
              </h3>
              <h4 className='uppercase text-center font-[700] lg:text-5xl  mt-4'>
                1,000,000,001
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tokenomics;
