import React from 'react'
import Hero from '../components/Elements/Hero'
import About from '../components/Elements/About'
import Sections from '../components/Elements/Sections'
import Tokenomics from '../components/Elements/Tokenomics'
import Buy from '../components/Elements/Buy'
import Roadmap from '../components/Elements/Roadmap'

const Home = () => {
  return (
    <>
    <div className='py-2 px-2 rounded-t-2xl mx-2 opacity-[1] bg-black'>
      <Hero/>
    </div>
    <div className='px-2'>
      <About/>
      <Sections/>
      <Tokenomics/>
      <Buy/>
      <Roadmap/>
    </div>
    </>
  )
}

export default Home