import React from "react";

const Roadmap = () => {
  return (
    <>
      <div className="roadmap py-16 lg:px-3">
        <div className="container-fluid lg:px-5">
          <div className="text-white">
            <h4 className=" font-[700] lg:text-6xl text-4xl">FUFU roadmap</h4>
            <h3 className=" font-[700] lg:text-9xl text-4xl">
              Trippy journeys
            </h3>
          </div>
          <div className="grid lg:grid-cols-3 gap-5 my-14">
            <div className="card_1 text-white px-4 py-3 imgg">
            <h4 className=" font-[700] lg:text-6xl text-4xl">PHASE</h4>
            <h3 className=" font-[700] lg:text-6xl text-4xl">
              01
            </h3>
           <div className="mt-4">
           <p className="my-2 font-medium">Get ready for hilarious memes and epic giveaways!</p>
            <p className="my-2 font-medium">Awakens your Crypto Shiba Spirit!</p>
            <p className="my-2 font-medium">The FUFU Coin Genesis</p>

           </div>
            </div>
            <div className="card_2 text-white px-4 py-3 imgg">
            <h4 className=" font-[700] lg:text-6xl text-4xl">PHASE</h4>
            <h3 className=" font-[700] lg:text-6xl text-4xl">
              02
            </h3>
           <div className="mt-4">
           <p className="my-2 font-medium">Spreads the Dogey Love</p>
            <p className="my-2 font-medium">Meme Machine Takes Flight</p>
            <p className="my-2 font-medium">Unleashing the FUFU Coin Horde</p>
         
           </div>
            </div>
            <div className="card_3 text-white px-4 py-3 imgg">
            <h4 className=" font-[700] lg:text-6xl text-4xl">PHASE</h4>
            <h3 className=" font-[700] lg:text-6xl text-4xl">
              03
            </h3>
           <div className="mt-4">
           <p className="my-2 font-medium">To the Moon and Beyond!</p>
            <p className="my-2 font-medium">Conquers the Cryptosphere</p>
            <p className="my-2 font-medium">The Reign of the Doge (FuFu Coin Takes Over)</p>

           </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Roadmap;
