/** @format */

import React from "react";

const About = () => {
  return (
    <>
      <div className='about_sec lg:px-3'>
        <div className='overlay'></div>
        <div className='container-fluid'>
          <div className='grid lg:grid-cols-2 py-5 px-2'>
            <div className='left_txt text-white w-3/2'>
              <h4 className='uppercase lg:font-[700] font-[800] lg:text-6xl text-4xl'>
                About
              </h4>
              <h3 className='capitalize lg:font-[700] font-[800] lg:text-8xl text-4xl'>
              FUFU
              </h3>
              <p className='font-sans lg:font-medium lg:text-lg mt-5'>
                In the heart of the internet, where anything is possible, we
                embodied the spirit of internet memes in the form of FUFU Coin.
                It commenced with a Shiba lnu pup, who attracted the masses with
                its preciousness and took the internet by storm. The quest to
                truly expand the spirit, meme enthusiasts gathered to dodge the
                boring and average investments and created the next big meme
                craze – the ultimate meme coin.
              </p>
              <a
                href='https://solscan.io/token/7QSCmz8oCApibDEmQRiHXabK15GmnJkoKGhGnjeVVWNz'
                rel='noopener noreferrer'
                target='_blank'
                className='font-sans  lg:text-lg text-xs mt-4 no-underline  font-bold text-yellow-300'>
                CA: 7QSCmz8oCApibDEmQRiHXabK15GmnJkoKGhGnjeVVWNz
              </a>
              <div className='mt-5'>
                <a
                  href='https://t.me/fufucoin_official'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <button className='uppercase px-4 py-2 mx-1'>
                    {" "}
                    <img
                      src='assets/images/telegram.png'
                      className='lg:w-7 w-5  inline-block '
                      alt=''
                    />{" "}
                    telegram
                  </button>
                </a>
                <a
                  href='https://twitter.com/Fufucoin_'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <button className='uppercase px-4 py-2 mx-1'>
                    {" "}
                    <img
                      src='assets/images/Twitter.png'
                      className='lg:w-7 w-5 inline-block'
                      alt=''
                    />
                  </button>
                </a>
                <a
                href='https://game.fufusol.xyz/'
                target='_blank'
                rel='noopener noreferrer'>
                <button className='fufu-btn uppercase px-4 py-2 mx-1 m-auto'>
                  Game Play
                </button>
              </a>
              </div>
            </div>
            <div className='flex justify-center items-center flex-wrap'>
              <img
                src='/fufu.png'
                className='lg:w-2/4 w-2/6 rotate-[31deg] transform scale-x-[-1] shakee'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>

      <div className='about_sec lg:px-3'>
        <div className='overlay'></div>
        <div className='container-fluid'>
          <div className='grid lg:grid-cols-1 py-5 px-2'>
            <div className='left_txt text-white w-3/2'>
              <h4 className='uppercase lg:font-[700] font-[800] lg:text-6xl text-4xl'>
              FUFU Coin: The Doge you can’t resist!
              </h4>
              <p className='font-sans lg:font-medium lg:text-lg mt-5'>
                Forget boring investments. FUFU Coin is the hottest meme coin on
                the block, powered by pure cuteness and comes with a mission.
                <br />
                <br />
                Ever wondered that stock market is something that would make you
                to snooze?? We get it! That’s why we created FUFU Coin! It is
                the meme coin for all of us and a chance to jump into the crypto
                game and have some fun while you are at it.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
