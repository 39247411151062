/** @format */

import React from "react";
import Timer1 from "../Utils/Timer1.js";

const Header = () => {
  return (
    <>
      <nav
        className='navbar navbar-expand-lg font-sans   bg-black  '
        style={{ zIndex: "1" }}
        id='navbar_top'>
        <div className='container-fluid'>
          <div className=' navbar-collapse'>
            <ul className='navbar-nav me-auto text-center mb-2  items-center mb-lg-0'>
              <li className='nav-item '>
                <a
                  className='nav-link  '
                  href='https://raydium.io/swap/?inputCurrency=7QSCmz8oCApibDEmQRiHXabK15GmnJkoKGhGnjeVVWNz&outputCurrency=sol&fixed=in'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img
                    src='assets/images/logo-with-text.svg'
                    className=' mt-2 object-contain '
                    width='250'
                    alt=''
                  />
                </a>
              </li>
              <li className='nav-item '>
                <a
                  className='nav-link  '
                  href='https://www.dexview.com/solana/7QSCmz8oCApibDEmQRiHXabK15GmnJkoKGhGnjeVVWNz'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <img
                    src='assets/images/dexview.svg'
                    className=' mt-2 object-contain '
                    width='250'
                    alt=''
                  />
                </a>
              </li>
              <li className='nav-item '>
                <a
                  className='nav-link  '
                  href='https://game.fufusol.xyz/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <button className='fufu-btn uppercase px-4 py-2 mx-1 m-auto'>
                    Game Play
                  </button>
                </a>
              </li>
            </ul>

            <span className=' navbar-text'>
              <ul className='navbar-nav ms-auto text-center mb-2  mb-lg-0'>
                <li className='nav-item '>
                  <a
                    className='nav-link  '
                    href='https://raydium.io/swap/?inputCurrency=7QSCmz8oCApibDEmQRiHXabK15GmnJkoKGhGnjeVVWNz&outputCurrency=sol&fixed=in'
                    target='_blank'
                    rel='noopener noreferrer'>
                    <div class=' transform  fufu-btn font-bold   animate-bounce  text-xl    px-4 py-2'>
                      $FUFU is Now Live on Raydium
                    </div>
                  </a>
                </li>
              </ul>
            </span>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
