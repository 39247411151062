/** @format */

import React from "react";

const Sections = () => {
  return (
    <>
      <div className='section imgg py-12'>
        <div className='overlay'></div>
        <div className='container-fluid'>
          <div className='grid lg:grid-cols-2'>
            <div className='flex justify-center items-center'>
              <img
                src='assets/images/2.png'
                className='w-2/4 rotate-[347deg] transform scale-x-[1] shakee'
                alt=''
              />
            </div>
            <div className='right_txt text-white'>
              <h4 className='uppercase lg:font-[700] font-[800] lg:text-6xl text-4xl'>
                WHY
              </h4>
              <h3 className='capitalize lg:font-[700] font-[800] lg:text-9xl text-4xl'>
                Why <br /> FUFU Coin?
              </h3>
              <p className='font-sans font-normal text-lg mt-4'>
                Look, we all want cute things in our lives. But wouldn't it be
                awesome if that cuteness could, you know, change your life?
                That's the FUFU Coin dream!
              </p>
              <p className='font-sans font-normal text-lg mt-4'>
                - FUFU Coin is by the people, for the people
              </p>
              <p className='font-sans font-normal text-lg mt-4'>
                - Transaction fees? No way!! We are all about memeing our way to
                riches (or at least to the trophy).
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sections;
